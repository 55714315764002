// import { cookieAuthGql } from '@healthlabs/config';

import { fetchJson } from './fetchJson.js';

export interface MagentoRestClientOptions {
    storeId: string;
    baseUrl?: string;
    auth?: string;
}

export function magentoRestClient(options: MagentoRestClientOptions) {
    const url = `${options.baseUrl || ''}/rest/${options.storeId}`;
    const headers: Record<string, string> = {
        Cookie: 'lekko-auth=TmzeL1Xs47GpiBFPG8WyOq',
    };

    if (options.auth) {
        headers['Authorization'] = `Bearer ${options.auth}`;
    }

    return {
        get,
        post,
        put,
    };

    function get<T>(path: string) {
        return fetchJson<T>({
            url: getUrl(path),
            headers,
        });
    }

    function post<T>(path: string, data?: unknown) {
        return fetchJson<T>({
            method: 'POST',
            url: getUrl(path),
            data: data,
            headers,
        });
    }

    function put<T>(path: string, data?: unknown) {
        return fetchJson<T>({
            method: 'PUT',
            url: getUrl(path),
            data: data,
            headers,
        });
    }

    function getUrl(path: string) {
        return url + normalizePath(path);
    }

    function normalizePath(path: string) {
        if (path.startsWith('/')) {
            return path;
        }

        return `/${path}`;
    }
}
