export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, minimum-scale=1, viewport-fit=cover"},{"name":"format-detection","content":"telephone=no"},{"name":"theme-color","content":"#FFF"},{"name":"facebook-domain-verification","content":"uoi224mgqf79rrlpp67ipd9lh0kv9p"}],"link":[{"rel":"preconnect","href":"https://use.typekit.net/","crossorigin":""},{"rel":"preconnect","href":"https://images.prismic.io/","crossorigin":""},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon/016.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon/032.png"},{"rel":"icon","type":"image/png","sizes":"152x152","href":"/favicon/152.png"},{"rel":"icon","type":"image/png","sizes":"180x180","href":"/favicon/180.png"},{"rel":"icon","type":"image/png","sizes":"192x192","href":"/favicon/192.png"}],"style":[],"script":[],"noscript":[],"title":"Lekko"}

export const appLayoutTransition = {"name":"fade","mode":"out-in"}

export const appPageTransition = {"name":"fade","mode":"out-in"}

export const appCdnURL = ""

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null