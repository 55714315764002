import { default as indexX5IdYdjYF7Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/index.vue?macro=true";
import { default as mainSVLwSekjPfMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/main.vue?macro=true";
import { default as manage_45ccjFDMPfGkJdMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account/manage-cc.vue?macro=true";
import { default as account4qBVU05r7FMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account.vue?macro=true";
import { default as manage_45permissionsvRYpsWmgiNMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/manage-permissions.vue?macro=true";
import { default as cartdTxWJilLRtMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/cart.vue?macro=true";
import { default as indexCrd8OLPsLWMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout/index.vue?macro=true";
import { default as databpQcPqcJGzMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout/data.vue?macro=true";
import { default as addressK2meUexOvYMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout/address.vue?macro=true";
import { default as shippingOuGjHOJE4fMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout/shipping.vue?macro=true";
import { default as paymentBJIcZgTVCpMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout/payment.vue?macro=true";
import { default as sumupruzrvFoxV7Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout/sumup.vue?macro=true";
import { default as checkoutrW3INbcGrWMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout.vue?macro=true";
import { default as checkout_45retrypkW9ITFhcSMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout-retry.vue?macro=true";
import { default as checkout_45completeJg3zymujtNMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout-complete.vue?macro=true";
import { default as checkout_45complete_45upsell5NHbTIb1gNMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout-complete-upsell.vue?macro=true";
import { default as checkout_45confirmWeHkAVlfZ1Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout-confirm.vue?macro=true";
import { default as checkout_45confirm_45paypal5Q37Hc0RlpMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout-confirm-paypal.vue?macro=true";
import { default as re_45orderPLo0Z1BTVkMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/re-order.vue?macro=true";
import { default as product_45listCvcKUgqHdjMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/products/product-list.vue?macro=true";
import { default as product_45detailsuOkUuVVTHEMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/products/product-details.vue?macro=true";
import { default as indexTEy3hBIbX9Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/blog/index.vue?macro=true";
import { default as categoryezt0xDbjhUMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/blog/category.vue?macro=true";
import { default as blog68RyrdxqLdMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/blog.vue?macro=true";
import { default as blog_45postojfiQT5LgEMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/blog-post.vue?macro=true";
import { default as loginwqQtVd9L5gMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/login.vue?macro=true";
import { default as registerJgt437o3nSMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/register.vue?macro=true";
import { default as forgot_45password8ywJKUygyLMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/forgot-password.vue?macro=true";
import { default as contacth04Uaabx9YMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/contact.vue?macro=true";
import { default as faqtg9S6KoVUcMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/faq.vue?macro=true";
import { default as review_45form1oRrkwn3p2Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-form.vue?macro=true";
import { default as review_45statusnp4PPKVgDoMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-status.vue?macro=true";
import { default as review_45listpexLPBy1mQMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-list.vue?macro=true";
import { default as indexp8gTGjkV5WMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/terms/index.vue?macro=true";
import { default as privacy1hW9tSdceFMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/terms/privacy.vue?macro=true";
import { default as returnAtatGuSTrKMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/terms/return.vue?macro=true";
import { default as payments2UZWZv16UvMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/terms/payments.vue?macro=true";
import { default as deliveryN3rj6C2GlkMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/terms/delivery.vue?macro=true";
import { default as pracaK73QuG2yiPMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/praca.vue?macro=true";
import { default as about_45usrnpAY6MaV2Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/about-us.vue?macro=true";
import { default as newsletter_45confirmationnQNMuO8lg3Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/newsletter-confirmation.vue?macro=true";
import { default as cookie_45settingsa4wIfwOk4uMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/cookie-settings.vue?macro=true";
import { default as dashboardeFx35MkwVYMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/care-club/dashboard.vue?macro=true";
import { default as dashboardfGRfcrLEBPMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/mgm/dashboard.vue?macro=true";
import { default as recommended_45successB4m8dK4EX0Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/mgm/recommended-success.vue?macro=true";
import { default as recommended_45failuressO8cwkBX2Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/mgm/recommended-failure.vue?macro=true";
import { default as recommendedlw2n5qxUw8Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/mgm/recommended.vue?macro=true";
import { default as static_45page87bEfvgmAdMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/static-page.vue?macro=true";
import { default as _404pBBbXiHifHMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/404.vue?macro=true";
export default [
  {
    name: indexX5IdYdjYF7Meta?.name ?? "index",
    path: indexX5IdYdjYF7Meta?.path ?? "/",
    meta: {...(indexX5IdYdjYF7Meta || {}), ...{"layout":"empty"}},
    alias: indexX5IdYdjYF7Meta?.alias || [],
    redirect: indexX5IdYdjYF7Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mainSVLwSekjPfMeta?.name ?? "main___pl",
    path: mainSVLwSekjPfMeta?.path ?? "/pl",
    meta: {...(mainSVLwSekjPfMeta || {}), ...{"id":"main","locale":"pl","seo":true}},
    alias: mainSVLwSekjPfMeta?.alias || [],
    redirect: mainSVLwSekjPfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/main.vue").then(m => m.default || m)
  },
  {
    name: account4qBVU05r7FMeta?.name ?? "account___pl",
    path: account4qBVU05r7FMeta?.path ?? "/pl/moje-konto",
    children: [
  {
    name: manage_45ccjFDMPfGkJdMeta?.name ?? "account-manage-cc___pl",
    path: manage_45ccjFDMPfGkJdMeta?.path ?? "/pl/moje-konto/zarzadzaj-zapisanymi-kartami",
    meta: {...(manage_45ccjFDMPfGkJdMeta || {}), ...{"id":"account-manage-cc","locale":"pl","seo":false,"layout":"empty"}},
    alias: manage_45ccjFDMPfGkJdMeta?.alias || [],
    redirect: manage_45ccjFDMPfGkJdMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/manage-cc.vue").then(m => m.default || m)
  }
],
    meta: {...(account4qBVU05r7FMeta || {}), ...{"id":"account","locale":"pl","seo":false,"layout":"empty"}},
    alias: account4qBVU05r7FMeta?.alias || [],
    redirect: account4qBVU05r7FMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account.vue").then(m => m.default || m)
  },
  {
    name: manage_45permissionsvRYpsWmgiNMeta?.name ?? "account-consents___pl",
    path: manage_45permissionsvRYpsWmgiNMeta?.path ?? "/pl/moje-konto/zgody-marketingowe",
    meta: {...(manage_45permissionsvRYpsWmgiNMeta || {}), ...{"id":"account-consents","locale":"pl","seo":false,"layout":"empty"}},
    alias: manage_45permissionsvRYpsWmgiNMeta?.alias || [],
    redirect: manage_45permissionsvRYpsWmgiNMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/manage-permissions.vue").then(m => m.default || m)
  },
  {
    name: cartdTxWJilLRtMeta?.name ?? "cart___pl",
    path: cartdTxWJilLRtMeta?.path ?? "/pl/koszyk",
    meta: {...(cartdTxWJilLRtMeta || {}), ...{"id":"cart","locale":"pl","seo":false,"layout":"empty"}},
    alias: cartdTxWJilLRtMeta?.alias || [],
    redirect: cartdTxWJilLRtMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: checkoutrW3INbcGrWMeta?.name ?? "checkout___pl",
    path: checkoutrW3INbcGrWMeta?.path ?? "/pl/zamawiam",
    children: [
  {
    name: indexCrd8OLPsLWMeta?.name ?? "checkout-index___pl",
    path: indexCrd8OLPsLWMeta?.path ?? "/pl/zamawiam",
    meta: {...(indexCrd8OLPsLWMeta || {}), ...{"id":"checkout-index","locale":"pl","seo":false,"layout":"checkout"}},
    alias: indexCrd8OLPsLWMeta?.alias || [],
    redirect: indexCrd8OLPsLWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: databpQcPqcJGzMeta?.name ?? "checkout-data___pl",
    path: databpQcPqcJGzMeta?.path ?? "/pl/zamawiam/twoje-dane",
    meta: {...(databpQcPqcJGzMeta || {}), ...{"id":"checkout-data","locale":"pl","seo":false,"layout":"checkout"}},
    alias: databpQcPqcJGzMeta?.alias || [],
    redirect: databpQcPqcJGzMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/data.vue").then(m => m.default || m)
  },
  {
    name: addressK2meUexOvYMeta?.name ?? "checkout-address___pl",
    path: addressK2meUexOvYMeta?.path ?? "/pl/zamawiam/adres",
    meta: {...(addressK2meUexOvYMeta || {}), ...{"id":"checkout-address","locale":"pl","seo":false,"layout":"checkout"}},
    alias: addressK2meUexOvYMeta?.alias || [],
    redirect: addressK2meUexOvYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/address.vue").then(m => m.default || m)
  },
  {
    name: shippingOuGjHOJE4fMeta?.name ?? "checkout-shipping___pl",
    path: shippingOuGjHOJE4fMeta?.path ?? "/pl/zamawiam/dostawa",
    meta: {...(shippingOuGjHOJE4fMeta || {}), ...{"id":"checkout-shipping","locale":"pl","seo":false,"layout":"checkout"}},
    alias: shippingOuGjHOJE4fMeta?.alias || [],
    redirect: shippingOuGjHOJE4fMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: paymentBJIcZgTVCpMeta?.name ?? "checkout-payment___pl",
    path: paymentBJIcZgTVCpMeta?.path ?? "/pl/zamawiam/platnosc",
    meta: {...(paymentBJIcZgTVCpMeta || {}), ...{"id":"checkout-payment","locale":"pl","seo":false,"layout":"checkout"}},
    alias: paymentBJIcZgTVCpMeta?.alias || [],
    redirect: paymentBJIcZgTVCpMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: sumupruzrvFoxV7Meta?.name ?? "checkout-sumup___pl",
    path: sumupruzrvFoxV7Meta?.path ?? "/pl/zamawiam/podsumowanie",
    meta: {...(sumupruzrvFoxV7Meta || {}), ...{"id":"checkout-sumup","locale":"pl","seo":false,"layout":"checkout"}},
    alias: sumupruzrvFoxV7Meta?.alias || [],
    redirect: sumupruzrvFoxV7Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/sumup.vue").then(m => m.default || m)
  }
],
    meta: {...(checkoutrW3INbcGrWMeta || {}), ...{"id":"checkout","locale":"pl","seo":false,"layout":"checkout"}},
    alias: checkoutrW3INbcGrWMeta?.alias || [],
    redirect: checkoutrW3INbcGrWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkout_45retrypkW9ITFhcSMeta?.name ?? "checkout-retry___pl",
    path: checkout_45retrypkW9ITFhcSMeta?.path ?? "/pl/zamawiam/ponow-platnosc",
    meta: {...(checkout_45retrypkW9ITFhcSMeta || {}), ...{"id":"checkout-retry","locale":"pl","seo":false,"layout":"checkout"}},
    alias: checkout_45retrypkW9ITFhcSMeta?.alias || [],
    redirect: checkout_45retrypkW9ITFhcSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout-retry.vue").then(m => m.default || m)
  },
  {
    name: checkout_45completeJg3zymujtNMeta?.name ?? "checkout-complete___pl",
    path: checkout_45completeJg3zymujtNMeta?.path ?? "/pl/zamawiam/zamowienie-zlozone",
    meta: {...(checkout_45completeJg3zymujtNMeta || {}), ...{"id":"checkout-complete","locale":"pl","seo":false}},
    alias: checkout_45completeJg3zymujtNMeta?.alias || [],
    redirect: checkout_45completeJg3zymujtNMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout-complete.vue").then(m => m.default || m)
  },
  {
    name: checkout_45complete_45upsell5NHbTIb1gNMeta?.name ?? "checkout-complete-upsell___pl",
    path: checkout_45complete_45upsell5NHbTIb1gNMeta?.path ?? "/pl/zamawiam/zamowienie-zlozone-upsell",
    meta: {...(checkout_45complete_45upsell5NHbTIb1gNMeta || {}), ...{"id":"checkout-complete-upsell","locale":"pl","seo":false}},
    alias: checkout_45complete_45upsell5NHbTIb1gNMeta?.alias || [],
    redirect: checkout_45complete_45upsell5NHbTIb1gNMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout-complete-upsell.vue").then(m => m.default || m)
  },
  {
    name: checkout_45confirmWeHkAVlfZ1Meta?.name ?? "checkout-confirm___pl",
    path: checkout_45confirmWeHkAVlfZ1Meta?.path ?? "/pl/zamawiam/potwierdzenie",
    meta: {...(checkout_45confirmWeHkAVlfZ1Meta || {}), ...{"id":"checkout-confirm","locale":"pl","seo":false,"layout":"checkout"}},
    alias: checkout_45confirmWeHkAVlfZ1Meta?.alias || [],
    redirect: checkout_45confirmWeHkAVlfZ1Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout-confirm.vue").then(m => m.default || m)
  },
  {
    name: checkout_45confirm_45paypal5Q37Hc0RlpMeta?.name ?? "checkout-confirm-paypal___pl",
    path: checkout_45confirm_45paypal5Q37Hc0RlpMeta?.path ?? "/pl/zamawiam/potwierdzenie-paypal",
    meta: {...(checkout_45confirm_45paypal5Q37Hc0RlpMeta || {}), ...{"id":"checkout-confirm-paypal","locale":"pl","seo":false,"layout":"checkout"}},
    alias: checkout_45confirm_45paypal5Q37Hc0RlpMeta?.alias || [],
    redirect: checkout_45confirm_45paypal5Q37Hc0RlpMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout-confirm-paypal.vue").then(m => m.default || m)
  },
  {
    name: re_45orderPLo0Z1BTVkMeta?.name ?? "re-order___pl",
    path: re_45orderPLo0Z1BTVkMeta?.path ?? "/pl/ponow-zamowienie",
    meta: {...(re_45orderPLo0Z1BTVkMeta || {}), ...{"id":"re-order","locale":"pl","seo":false,"layout":"empty"}},
    alias: re_45orderPLo0Z1BTVkMeta?.alias || [],
    redirect: re_45orderPLo0Z1BTVkMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/re-order.vue").then(m => m.default || m)
  },
  {
    name: product_45listCvcKUgqHdjMeta?.name ?? "products___pl",
    path: product_45listCvcKUgqHdjMeta?.path ?? "/pl/produkty/:categoryUid?",
    meta: {...(product_45listCvcKUgqHdjMeta || {}), ...{"id":"products","locale":"pl","seo":true}},
    alias: product_45listCvcKUgqHdjMeta?.alias || [],
    redirect: product_45listCvcKUgqHdjMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/products/product-list.vue").then(m => m.default || m)
  },
  {
    name: product_45detailsuOkUuVVTHEMeta?.name ?? "product-details___pl",
    path: product_45detailsuOkUuVVTHEMeta?.path ?? "/pl/produkt/:productUid",
    meta: {...(product_45detailsuOkUuVVTHEMeta || {}), ...{"id":"product-details","locale":"pl","seo":true}},
    alias: product_45detailsuOkUuVVTHEMeta?.alias || [],
    redirect: product_45detailsuOkUuVVTHEMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/products/product-details.vue").then(m => m.default || m)
  },
  {
    name: blog68RyrdxqLdMeta?.name ?? "blog___pl",
    path: blog68RyrdxqLdMeta?.path ?? "/pl/blog",
    children: [
  {
    name: indexTEy3hBIbX9Meta?.name ?? "blog-index___pl",
    path: indexTEy3hBIbX9Meta?.path ?? "/pl/blog",
    meta: {...(indexTEy3hBIbX9Meta || {}), ...{"id":"blog-index","locale":"pl","seo":true}},
    alias: indexTEy3hBIbX9Meta?.alias || [],
    redirect: indexTEy3hBIbX9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: categoryezt0xDbjhUMeta?.name ?? "blog-category___pl",
    path: categoryezt0xDbjhUMeta?.path ?? "/pl/blog/kategorie/:category",
    meta: {...(categoryezt0xDbjhUMeta || {}), ...{"id":"blog-category","locale":"pl","seo":true}},
    alias: categoryezt0xDbjhUMeta?.alias || [],
    redirect: categoryezt0xDbjhUMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/blog/category.vue").then(m => m.default || m)
  }
],
    meta: {...(blog68RyrdxqLdMeta || {}), ...{"id":"blog","locale":"pl","seo":true}},
    alias: blog68RyrdxqLdMeta?.alias || [],
    redirect: blog68RyrdxqLdMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: blog_45postojfiQT5LgEMeta?.name ?? "blog-post___pl",
    path: blog_45postojfiQT5LgEMeta?.path ?? "/pl/blog/:post",
    meta: {...(blog_45postojfiQT5LgEMeta || {}), ...{"id":"blog-post","locale":"pl","seo":true}},
    alias: blog_45postojfiQT5LgEMeta?.alias || [],
    redirect: blog_45postojfiQT5LgEMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/blog-post.vue").then(m => m.default || m)
  },
  {
    name: loginwqQtVd9L5gMeta?.name ?? "login___pl",
    path: loginwqQtVd9L5gMeta?.path ?? "/pl/login",
    meta: {...(loginwqQtVd9L5gMeta || {}), ...{"id":"login","locale":"pl","seo":true}},
    alias: loginwqQtVd9L5gMeta?.alias || [],
    redirect: loginwqQtVd9L5gMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registerJgt437o3nSMeta?.name ?? "register___pl",
    path: registerJgt437o3nSMeta?.path ?? "/pl/rejestracja",
    meta: {...(registerJgt437o3nSMeta || {}), ...{"id":"register","locale":"pl","seo":true}},
    alias: registerJgt437o3nSMeta?.alias || [],
    redirect: registerJgt437o3nSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/register.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password8ywJKUygyLMeta?.name ?? "forgot-password___pl",
    path: forgot_45password8ywJKUygyLMeta?.path ?? "/pl/zapomnialem-hasla",
    meta: {...(forgot_45password8ywJKUygyLMeta || {}), ...{"id":"forgot-password","locale":"pl","seo":false}},
    alias: forgot_45password8ywJKUygyLMeta?.alias || [],
    redirect: forgot_45password8ywJKUygyLMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: contacth04Uaabx9YMeta?.name ?? "contact___pl",
    path: contacth04Uaabx9YMeta?.path ?? "/pl/kontakt",
    meta: {...(contacth04Uaabx9YMeta || {}), ...{"id":"contact","locale":"pl","seo":true}},
    alias: contacth04Uaabx9YMeta?.alias || [],
    redirect: contacth04Uaabx9YMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: faqtg9S6KoVUcMeta?.name ?? "faq___pl",
    path: faqtg9S6KoVUcMeta?.path ?? "/pl/faq",
    meta: {...(faqtg9S6KoVUcMeta || {}), ...{"id":"faq","locale":"pl","seo":true}},
    alias: faqtg9S6KoVUcMeta?.alias || [],
    redirect: faqtg9S6KoVUcMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: review_45form1oRrkwn3p2Meta?.name ?? "review-form___pl",
    path: review_45form1oRrkwn3p2Meta?.path ?? "/pl/zostaw-opinie",
    meta: {...(review_45form1oRrkwn3p2Meta || {}), ...{"id":"review-form","locale":"pl","seo":false,"layout":"empty"}},
    alias: review_45form1oRrkwn3p2Meta?.alias || [],
    redirect: review_45form1oRrkwn3p2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-form.vue").then(m => m.default || m)
  },
  {
    name: review_45statusnp4PPKVgDoMeta?.name ?? "review-status___pl",
    path: review_45statusnp4PPKVgDoMeta?.path ?? "/pl/zaakceptuj-opinie",
    meta: {...(review_45statusnp4PPKVgDoMeta || {}), ...{"id":"review-status","locale":"pl","seo":false,"layout":"empty"}},
    alias: review_45statusnp4PPKVgDoMeta?.alias || [],
    redirect: review_45statusnp4PPKVgDoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-status.vue").then(m => m.default || m)
  },
  {
    name: review_45listpexLPBy1mQMeta?.name ?? "review-list___pl",
    path: review_45listpexLPBy1mQMeta?.path ?? "/pl/opinie",
    meta: {...(review_45listpexLPBy1mQMeta || {}), ...{"id":"review-list","locale":"pl","seo":true}},
    alias: review_45listpexLPBy1mQMeta?.alias || [],
    redirect: review_45listpexLPBy1mQMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-list.vue").then(m => m.default || m)
  },
  {
    name: indexp8gTGjkV5WMeta?.name ?? "terms___pl",
    path: indexp8gTGjkV5WMeta?.path ?? "/pl/regulamin",
    meta: {...(indexp8gTGjkV5WMeta || {}), ...{"id":"terms","locale":"pl","seo":true}},
    alias: indexp8gTGjkV5WMeta?.alias || [],
    redirect: indexp8gTGjkV5WMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: privacy1hW9tSdceFMeta?.name ?? "terms-privacy___pl",
    path: privacy1hW9tSdceFMeta?.path ?? "/pl/polityka-prywatnosci",
    meta: {...(privacy1hW9tSdceFMeta || {}), ...{"id":"terms-privacy","locale":"pl","seo":true}},
    alias: privacy1hW9tSdceFMeta?.alias || [],
    redirect: privacy1hW9tSdceFMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/privacy.vue").then(m => m.default || m)
  },
  {
    name: returnAtatGuSTrKMeta?.name ?? "terms-return___pl",
    path: returnAtatGuSTrKMeta?.path ?? "/pl/zwroty",
    meta: {...(returnAtatGuSTrKMeta || {}), ...{"id":"terms-return","locale":"pl","seo":true}},
    alias: returnAtatGuSTrKMeta?.alias || [],
    redirect: returnAtatGuSTrKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/return.vue").then(m => m.default || m)
  },
  {
    name: payments2UZWZv16UvMeta?.name ?? "terms-payments___pl",
    path: payments2UZWZv16UvMeta?.path ?? "/pl/metody-platnosci",
    meta: {...(payments2UZWZv16UvMeta || {}), ...{"id":"terms-payments","locale":"pl","seo":true}},
    alias: payments2UZWZv16UvMeta?.alias || [],
    redirect: payments2UZWZv16UvMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/payments.vue").then(m => m.default || m)
  },
  {
    name: deliveryN3rj6C2GlkMeta?.name ?? "terms-delivery___pl",
    path: deliveryN3rj6C2GlkMeta?.path ?? "/pl/metody-dostawy",
    meta: {...(deliveryN3rj6C2GlkMeta || {}), ...{"id":"terms-delivery","locale":"pl","seo":true}},
    alias: deliveryN3rj6C2GlkMeta?.alias || [],
    redirect: deliveryN3rj6C2GlkMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/delivery.vue").then(m => m.default || m)
  },
  {
    name: pracaK73QuG2yiPMeta?.name ?? "praca___pl",
    path: pracaK73QuG2yiPMeta?.path ?? "/pl/praca",
    meta: {...(pracaK73QuG2yiPMeta || {}), ...{"id":"praca","locale":"pl","seo":true}},
    alias: pracaK73QuG2yiPMeta?.alias || [],
    redirect: pracaK73QuG2yiPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/praca.vue").then(m => m.default || m)
  },
  {
    name: about_45usrnpAY6MaV2Meta?.name ?? "about-us___pl",
    path: about_45usrnpAY6MaV2Meta?.path ?? "/pl/o-nas",
    meta: {...(about_45usrnpAY6MaV2Meta || {}), ...{"id":"about-us","locale":"pl","seo":true}},
    alias: about_45usrnpAY6MaV2Meta?.alias || [],
    redirect: about_45usrnpAY6MaV2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45confirmationnQNMuO8lg3Meta?.name ?? "newsletter-confirmation___pl",
    path: newsletter_45confirmationnQNMuO8lg3Meta?.path ?? "/pl/potwierdzenie-zapisu",
    meta: {...(newsletter_45confirmationnQNMuO8lg3Meta || {}), ...{"id":"newsletter-confirmation","locale":"pl","seo":false}},
    alias: newsletter_45confirmationnQNMuO8lg3Meta?.alias || [],
    redirect: newsletter_45confirmationnQNMuO8lg3Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: cookie_45settingsa4wIfwOk4uMeta?.name ?? "cookie-settings___pl",
    path: cookie_45settingsa4wIfwOk4uMeta?.path ?? "/pl/ustawienia-cookies",
    meta: {...(cookie_45settingsa4wIfwOk4uMeta || {}), ...{"id":"cookie-settings","locale":"pl","seo":false,"layout":"empty"}},
    alias: cookie_45settingsa4wIfwOk4uMeta?.alias || [],
    redirect: cookie_45settingsa4wIfwOk4uMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/cookie-settings.vue").then(m => m.default || m)
  },
  {
    name: dashboardeFx35MkwVYMeta?.name ?? "lekko-club___pl",
    path: dashboardeFx35MkwVYMeta?.path ?? "/pl/lekko-club",
    meta: {...(dashboardeFx35MkwVYMeta || {}), ...{"id":"lekko-club","locale":"pl","seo":true}},
    alias: dashboardeFx35MkwVYMeta?.alias || [],
    redirect: dashboardeFx35MkwVYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/care-club/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardfGRfcrLEBPMeta?.name ?? "mgm___pl",
    path: dashboardfGRfcrLEBPMeta?.path ?? "/pl/polecaj",
    meta: {...(dashboardfGRfcrLEBPMeta || {}), ...{"id":"mgm","locale":"pl","seo":false}},
    alias: dashboardfGRfcrLEBPMeta?.alias || [],
    redirect: dashboardfGRfcrLEBPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/mgm/dashboard.vue").then(m => m.default || m)
  },
  {
    name: recommended_45successB4m8dK4EX0Meta?.name ?? "mgm-recommended-success___pl",
    path: recommended_45successB4m8dK4EX0Meta?.path ?? "/pl/mgm/polecenie-udane",
    meta: {...(recommended_45successB4m8dK4EX0Meta || {}), ...{"id":"mgm-recommended-success","locale":"pl","seo":false}},
    alias: recommended_45successB4m8dK4EX0Meta?.alias || [],
    redirect: recommended_45successB4m8dK4EX0Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/mgm/recommended-success.vue").then(m => m.default || m)
  },
  {
    name: recommended_45failuressO8cwkBX2Meta?.name ?? "mgm-recommended-failure___pl",
    path: recommended_45failuressO8cwkBX2Meta?.path ?? "/pl/mgm/polecenie-nieudane",
    meta: {...(recommended_45failuressO8cwkBX2Meta || {}), ...{"id":"mgm-recommended-failure","locale":"pl","seo":false}},
    alias: recommended_45failuressO8cwkBX2Meta?.alias || [],
    redirect: recommended_45failuressO8cwkBX2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/mgm/recommended-failure.vue").then(m => m.default || m)
  },
  {
    name: recommendedlw2n5qxUw8Meta?.name ?? "mgm-recommended___pl",
    path: recommendedlw2n5qxUw8Meta?.path ?? "/pl/mgm",
    meta: {...(recommendedlw2n5qxUw8Meta || {}), ...{"id":"mgm-recommended","locale":"pl","seo":false}},
    alias: recommendedlw2n5qxUw8Meta?.alias || [],
    redirect: recommendedlw2n5qxUw8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/mgm/recommended.vue").then(m => m.default || m)
  },
  {
    name: static_45page87bEfvgmAdMeta?.name ?? "static-page___pl",
    path: static_45page87bEfvgmAdMeta?.path ?? "/pl/:page+",
    meta: {...(static_45page87bEfvgmAdMeta || {}), ...{"id":"static-page","locale":"pl","seo":true}},
    alias: static_45page87bEfvgmAdMeta?.alias || [],
    redirect: static_45page87bEfvgmAdMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/static-page.vue").then(m => m.default || m)
  },
  {
    name: _404pBBbXiHifHMeta?.name ?? "404___pl",
    path: _404pBBbXiHifHMeta?.path ?? "/:slug*",
    meta: {...(_404pBBbXiHifHMeta || {}), ...{"id":"404","layout":"empty"}},
    alias: _404pBBbXiHifHMeta?.alias || [],
    redirect: _404pBBbXiHifHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/404.vue").then(m => m.default || m)
  }
]