export const localeCodes = ["pl"]

export const localeMessages = {
  "pl": [{ key: "../locale/langs/pl-PL.json", load: () => import("../locale/langs/pl-PL.json" /* webpackChunkName: "lang_pl_PL_json_pl_PL_json" */) }],
}

export const additionalMessages = Object({"pl":[],})

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  nuxtI18nOptions.experimental = Object({"jsTsFormatResource":false})
  nuxtI18nOptions.precompile = Object({"strictMessage":true,"escapeHtml":false})
 const vueI18nConfigLoader = async (context, loader) => {
            const config = await loader().then(r => r.default || r)
            return typeof config === 'object'
              ? config
              : typeof config === 'function'
                ? await config(context)
                : {}
          }
  const vueI18n = await vueI18nConfigLoader(context, (() => import("\u0000@nuxtjs/i18n/__config__?target=../locale/vue-i18n.ts&c=125883f0" /* webpackChunkName: "__locale_vue_i18n_ts_125883f0" */)))
  nuxtI18nOptions.vueI18n = vueI18n
  nuxtI18nOptions.locales = [Object({"code":"pl","iso":"pl-PL","lang":"pl","title":"Polska / Poland","file":"pl-PL.json","storeId":"default","storePrefix":"pl_pl","country":"PL","currency":"PLN","freeShippingThreshold":200,"magentoMainCategory":"2","specificGeolocationCountryCodes":[]})]
  nuxtI18nOptions.defaultLocale = ""
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "prefix"
  nuxtI18nOptions.lazy = true
  nuxtI18nOptions.langDir = "locale/langs/"
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = false
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = ""
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "config"
  nuxtI18nOptions.pages = Object({})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.types = "composition"
  nuxtI18nOptions.debug = false
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({experimental: Object({"jsTsFormatResource":false}),precompile: Object({"strictMessage":true,"escapeHtml":false}),vueI18n: "",locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,types: "composition",debug: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"pl","iso":"pl-PL","lang":"pl","title":"Polska / Poland","file":"pl-PL.json","storeId":"default","storePrefix":"pl_pl","country":"PL","currency":"PLN","freeShippingThreshold":200,"magentoMainCategory":"2","specificGeolocationCountryCodes":[]})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const NUXT_I18N_PRECOMPILE_ENDPOINT = "/__i18n__/precompile"
export const NUXT_I18N_PRECOMPILED_LOCALE_KEY = "i18n-locales"
export const isSSG = false
export const isSSR = true
